import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EnterProps, EventTypes, GameMode, ISettledBet, UserBonus } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setIsFreeSpinsWin,
  setIsSlotBusy,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setSettledAmount,
  setSlotConfig,
  setStressful,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import i18n from '../../i18next';
import SlotMachine from '../../slotMachine';
import { PopupTypes, WinStages, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { getBetResult, getSpinResult, getWinStage, normalizeCoins } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class FreeRoundBonusController extends BaseController {
  public override gameMode: GameMode = GameMode.FREE_ROUND_BONUS;

  public static override the = new FreeRoundBonusController();

  private skipIdle = false;

  protected constructor() {
    super();

    eventManager.on(EventTypes.FREE_ROUND_BONUS_EXPIRED, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BASE_GAME);
    });
  }

  public override enterIdleState(prevState: States): void {
    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    if (prevState === States.SPIN) return;
    if (this.skipIdle) {
      this.skipIdle = false;
      return;
    }
    if (setCurrentBonus().currentRound === setCurrentBonus().rounds || !setCurrentBonus().isActive) {
      setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS_END);
      eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      eventManager.once(EventTypes.END_FREE_ROUND_BONUS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.BASE_GAME);
      });
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
      AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
    }, 20000);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    if (AudioApi.isPlaying(ISongs.BGM_BG_Base_Loop)) {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop });
    }
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    eventManager.emit(EventTypes.CLOSE_ALL_STARS);
    SlotMachine.the().spinSpinAnimation();
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());
    if (betResult.bet.result.winCoinAmount > 0) {
      Logic.the.changeState(States.WIN_PRESENTATION);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterWinPresentationState(_prevState: States): void {
    eventManager.once(EventTypes.END_CASCADE_FEATURE, () => {
      setTimeout(() => Logic.the.changeState(States.AFTER_WIN), 1000);
    });

    eventManager.emit(EventTypes.START_CASCADE_FEATURE, getBetResult(setBetResult()).bet.data.features.cascade);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const result = getBetResult(setBetResult());
    setLastRegularWinAmount(result.bet.result.winCoinAmount);
    if (getWinStage(result.bet.result.winCoinAmount) >= WinStages.BigWin) {
      eventManager.emit(EventTypes.HIDE_COUNT_UP, 0);
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, result.bet.result.winCoinAmount);
    } else {
      setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      const multiplier = normalizeCoins(result.bet.result.winCoinAmount) / normalizeCoins(setBetAmount());
      if (multiplier > 7) {
        AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
        return;
      }
      if (multiplier >= 5) {
        AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
        return;
      }
      if (multiplier >= 3) {
        AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
      }
    }
  }

  public override enterJingleState(_prevState: States): void {
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
    const result = getBetResult(setBetResult());
    if (result.bet.data.bonuses.length > 0) {
      const freeSpins = result.bet.data.bonuses.find((e) => !e.isFreeBet) as UserBonus;
      if (freeSpins) {
        AudioApi.play({
          type: ISongs.SFX_WIN_FeatureTrigger,
          stopPrev: true,
        });
        setIsFreeSpinsWin(true);
        eventManager.emit(EventTypes.WIN_STAR);
        setFreeRoundsBonus({ ...setCurrentBonus() });
        setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
        setCurrentBonus({
          ...freeSpins,
          isActive: true,
          currentRound: 0,
        });
        setTimeout(() => {
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.FREE_SPINS, {
            bonus: freeSpins,
          });
        }, 1000);
        return;
      }
    }
    Logic.the.changeState(States.IDLE);
  }

  public override setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    const freeSpins = result.bet.data?.bonuses?.find((e) => !e.isFreeBet) as UserBonus;

    if (!freeSpins) {
      setFreeRoundsTotalWin(setFreeRoundsTotalWin() + result.bet.result.winCoinAmount);
    }
    setBottomContainerTotalWin(setBottomContainerTotalWin() + result.bet.result.winCoinAmount);
    result.bet.result.spinResult = spinResult;
    setBetResult(result);
    setSettledAmount(result.balance.settled);
  }

  public override enterController(_prevGameMode: GameMode, props: EnterProps): void {
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    this.skipIdle = !!props?.skipIdle;
    if (!props?.endBonus && setFreeRoundsBonus().isActive) {
      setCurrentBonus({ ...setFreeRoundsBonus() });
    }
    eventManager.emit(EventTypes.UPDATE_FREE_ROUNDS_LEFT, setCurrentBonus().rounds - setCurrentBonus().currentRound);
    setCoinValue(setCurrentBonus().coinValue);
    setCoinAmount(setCurrentBonus().coinAmount);
    setBetAmount(setCurrentBonus().coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
    eventManager.emit(EventTypes.UPDATE_BET);
    setIsSlotBusy(false);
    setIsFreeSpinsWin(false);
    eventManager.emit(EventTypes.ENABLE_PAYTABLE);
    AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_STARS);

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setBottomContainerTotalWin() > 0) {
      setTimeout(() => {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      }, 0);
    }
    eventManager.once(EventTypes.START_FREE_ROUND_BONUS, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.IDLE);
    });
    if (props?.endBonus && setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound > 0) {
      eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
      eventManager.emit(
        EventTypes.UPDATE_FREE_ROUNDS_LEFT,
        setFreeRoundsBonus().rounds - setFreeRoundsBonus().currentRound,
      );
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
        callback: () => {
          setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive: false });
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          Logic.the.changeState(States.IDLE);
        },
      });
    } else if (setCurrentBonus().rounds - setCurrentBonus().currentRound === 0) {
      Logic.the.changeState(States.IDLE);
    } else if (!setFreeRoundsBonus().isActive || !setFreeRoundsBonus().currentRound) {
      PopupController.the.openPopup(PopupTypes.FREE_ROUNDS);
    } else {
      setCurrentBonus({ ...setCurrentBonus(), isActive: true });
      eventManager.emit(EventTypes.START_FREE_ROUND_BONUS);
    }
  }

  public override exitController(nextGameMode: GameMode): void {
    if (nextGameMode !== GameMode.BASE_GAME) {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    } else {
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    }
    if (setBrokenGame()) setBrokenGame(false);
    clearTimeout(this.slotIdleTimeout);
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
  }
}
