import i18n from 'i18next';
import { Spine } from 'pixi-spine';
import { ITextStyle, Loader, Sprite, Texture } from 'pixi.js';

import { Variables } from '../../config';
// import { Variables } from '../../config';
import { EventTypes } from '../../global.d';
import type Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { eventManager } from '../config';
import type { PopupProps } from '../d';

import { Popup } from './popup';

const textStyle: Partial<ITextStyle> = {
  align: 'center',
  fill: ['#fff', '#FFEE57'],
  fillGradientStops: [0, 1],
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 45,
  lineHeight: 55,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#4A00A9',
  strokeThickness: 18,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
  lineJoin: 'round',
};
export class RageModeInfoPopup extends Popup {
  protected background: Sprite;

  protected spine: Spine;

  protected text: TextField;

  protected backgroundFadeInAnimation: Animation;

  protected backgroundFadeOutAnimation: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  private spineListener = {
    complete: () => {
      this.backgroundFadeOutAnimation.start();
    },
  };

  private callback = () => {
    this.spine.state.removeListener(this.spineListener);
    clearTimeout(this.autoSkipDelay);
    this.removeListener('click', this.bindedCallback);
    this.removeListener('touchstart', this.bindedCallback);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.visible = false;
    eventManager.emit(EventTypes.START_RAGE_MODE);
  };

  private bindedCallback = this.callback.bind(this);

  constructor() {
    super();
    this.visible = false;
    this.interactive = true;
    this.text = new TextField(i18n.t('freeSpinsRageModeInfo'), 2000, 2000, textStyle);
    this.text.text.anchor.set(0.5, 0.5);
    this.background = this.initBackground();
    this.spine = new Spine(Loader.shared.resources['popups']!.spineData!);
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();
    this.addChild(this.background);
    this.addChild(this.spine);
  }

  private initBackground(): Sprite {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x000000;
    sprite.anchor.set(0.5, 0.5);
    return sprite;
  }

  protected initSpine(): Spine {
    const spine = new Spine(Loader.shared.resources['popups']!.spineData!);
    return spine;
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  protected override resize(_width: number, _height: number): void {
    this.background.width = _width;
    this.background.height = _height;
    this.position.set(_width / 2, _height / 2);
    const isLandscape = _width >= _height;
    if (isLandscape) {
      const factor = Math.min(_height / 1080, _width / 1920);
      this.spine.scale.set(factor);
    } else {
      const factor = Math.min(_height / 1920, _width / 1080);
      this.spine.scale.set(factor);
    }
  }

  public override show(_props?: PopupProps): void {
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.bindedCallback);
    this.on('click', this.bindedCallback);
    this.on('touchstart', this.bindedCallback);
    const factor = this.spine.scale.x;
    this.removeChild(this.spine);
    this.spine = new Spine(Loader.shared.resources['popups']!.spineData!);
    this.spine.scale.set(factor);
    this.addChildAt(this.spine, 1);
    this.autoSkipDelay = setTimeout(this.bindedCallback, 4000);
    this.backgroundFadeInAnimation.start();
    this.spine.state.addListener(this.spineListener);
    this.spine.state.setAnimation(5, 'rage_mode_info_pop_up', false);
    const textPlaceholder = this.spine.skeleton.findSlot('party_mode_info_place_holder').currentSprite as Sprite;
    textPlaceholder.texture = Texture.EMPTY;
    textPlaceholder.addChild(this.text.getText());
    this.visible = true;
  }
}
